import AbstractService from "./AbstractService";
import Axios from "axios";

export default class TransferRequestProductService extends AbstractService {
    private controller = "transfer-request-products";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public current(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/current`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }
}