var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},_vm._l((_vm.products),function(product,i){return _c('v-container',{key:product.id,staticClass:"px-0",attrs:{"fluid":""}},[(i !== 0)?_c('v-row',[_c('v-divider')],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(product.productName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.product-name')))])]),_c('div',{staticClass:"indigo--text darken-4 body-2 py-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(product.quantity)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.quantity')))])]),_c('span',[_vm._v("x")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatPrice(product.price))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.price')))])])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"product-comment body-2"},on),[_vm._v(" "+_vm._s(_vm.formatComment(product))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.comment-message')))])])],1),_c('v-col',{staticClass:"text-right",attrs:{"align-self":"center","cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"red--text darken-1"},on),[_vm._v(" "+_vm._s(_vm.formatTotalAmount(product.totalAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.total-amount')))])])],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }